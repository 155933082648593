import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Loader,
  Modal,
  MultiSelect,
  Pagination,
  Table,
  TextInput,
  Title,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useDebouncedValue, useDisclosure } from '@mantine/hooks';
import dayjs from 'dayjs';
import { formatDate } from '../../../utils/dateUtils';
import {
  AppointmentsIcon,
  CancelStudyIcon,
  CloudStatus,
  DownloadConclusionIcon,
  EditStudyIcon,
  ServicesIcon,
  SmsStatusIcons,
  RewriteResponsibleUserBtn,
  StudyStatusIcon,
} from './WorkList/AppointmentsIcon';
import { trpc } from '../../trpc';
import { Header } from '@ohif/ui';
import UserService from '../userService';
import { useNavigate } from 'react-router';
import { modals } from '@mantine/modals';
import NewVisitModal from '../../../components/NewVisitModal';
import { inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from '../../../../../medreview/server/src';
import { PanelConclusion } from '../../../../extensions/default/src/Panels';

const PER_PAGE = 25;
export const dbStatusesMap = {
  CREATED: { label: 'Создано', color: 'blue' },
  IN_PROGRESS: { label: 'В процессе', color: 'orange' },
  FINISHED: { label: 'Завершено', color: 'green' },
  CANCELLED: { label: 'Отменено', color: 'red' },
} as const;

export default function Studies() {
  const navigate = useNavigate();

  const user = trpc.general.getUser.useQuery();
  const dbServices = trpc.general.getServices.useQuery({
    orgIds: user.data?.organization_user.map(ou => ou.organization_id!),
  });
  const dbResponsibleUsers = trpc.general.getUsers.useQuery({
    orgIds: user.data?.organization_user.map(ou => ou.organization_id!),
    isResponsible: true,
  });
  const dbModalities = trpc.general.getModalities.useQuery({
    orgIds: user.data?.organization_user.map(ou => ou.organization_id!),
  });
  const dbPaymentTypes = trpc.general.getPaymentTypes.useQuery();

  const [page, setPage] = useState(1);
  const [iinOrFio, setIinOrFio] = useState<string | undefined>();
  const [debouncedIinOrFio] = useDebouncedValue(iinOrFio, 1500);
  const [dateFrom, setDateFrom] = useState<string | undefined>();
  const [dateTo, setDateTo] = useState<string | undefined>();
  const [desc, setDesc] = useState<string | undefined>();
  const [debouncedDesc] = useDebouncedValue(desc, 1500);
  const [modalities, setModalities] = useState<string[] | undefined>();
  const [services, setServices] = useState<string[] | undefined>();
  const [responsibleUserIds, setResponsibleUserIds] = useState<string[] | undefined>();
  const [paymentTypeIds, setPaymentTypeIds] = useState<string[] | undefined>();
  const [statuses, setStatuses] = useState<string[] | undefined>(
    Object.keys(dbStatusesMap).filter(ds => ds !== 'CANCELLED')
  );
  const [incorrectIIN, setIncorrectIIN] = useState<boolean | undefined>();
  const [incorrectPhone, setIncorrectPhone] = useState<boolean | undefined>();
  const [assignedToMe, setAssignedToMe] = useState<boolean | undefined>();
  const [hasPatientComments, setHasPatientComments] = useState<boolean | undefined>();
  const [waitsSecondReader, setWaitsSecondReader] = useState<boolean | undefined>();

  const studies = trpc.study.getAll.useQuery({
    skip: (page - 1) * PER_PAGE,
    take: PER_PAGE,
    iinOrFio: debouncedIinOrFio,
    dateFrom: dateFrom ? dayjs(dateFrom).format('YYYYMMDD') : undefined,
    dateTo: dateTo ? dayjs(dateTo).format('YYYYMMDD') : undefined,
    desc: debouncedDesc,
    modalities,
    services,
    responsibleUserIds: responsibleUserIds ? responsibleUserIds.map(id => +id) : undefined,
    paymentTypeIds: paymentTypeIds ? paymentTypeIds.map(id => +id) : undefined,
    statuses,
    incorrectIIN,
    incorrectPhone,
    assignedToMe,
    hasPatientComments,
    waitsSecondReader,
  });

  return (
    <>
      <Header
        isSticky={false}
        menuOptions={[
          {
            title: 'отчеты',
            icon: 'report-page',
            onClick: () => navigate('/report'),
          },
          {
            icon: 'power-off',
            title: 'Выйти',
            onClick: () => UserService.doLogout(),
          },
        ]}
        isReturnEnabled={false}
      />

      <Flex className="items-center justify-between p-5">
        <Title
          order={2}
          className="hidden sm:inline-block"
        >
          Список обследований
        </Title>

        <Button
          className="mr-3"
          onClick={() =>
            modals.open({
              children: <NewVisitModal />,
              size: 1050,
            })
          }
        >
          Новое посещение
        </Button>
      </Flex>

      <Box className="p-5">
        <Flex className="flex-wrap items-end gap-2">
          <TextInput
            size="xs"
            className="w-full sm:w-44"
            label="ИИН или ФИО"
            value={iinOrFio || ''}
            onChange={e => {
              setIinOrFio(e.target.value || undefined);
              setPage(1);
            }}
          />

          <Flex className="w-full items-end gap-2 sm:w-auto">
            <DateInput
              size="xs"
              clearable
              label="Дата"
              className="w-1/2 sm:w-[84px]"
              maxDate={dateTo ? new Date(dateTo) : undefined}
              value={dateFrom ? new Date(dateFrom) : null}
              dateParser={input => dayjs(input, 'DD.MM.YYYY').toDate()}
              placeholder="от"
              valueFormat="DD.MM.YYYY"
              onChange={date => {
                setDateFrom(date ? dayjs(date).format('YYYY-MM-DD') : undefined);
                setPage(1);
              }}
            />

            <DateInput
              size="xs"
              clearable
              className="w-1/2 sm:w-[84px]"
              minDate={dateFrom ? new Date(dateFrom) : undefined}
              value={dateTo ? new Date(dateTo) : null}
              dateParser={input => dayjs(input, 'DD.MM.YYYY').toDate()}
              placeholder="до"
              valueFormat="DD.MM.YYYY"
              onChange={date => {
                setDateTo(date ? dayjs(date).format('YYYY-MM-DD') : undefined);
                setPage(1);
              }}
            />
          </Flex>

          <TextInput
            size="xs"
            className="w-full sm:w-44"
            label="Описание"
            value={desc || ''}
            onChange={e => {
              setDesc(e.target.value || undefined);
              setPage(1);
            }}
          />

          <MultiSelect
            size="xs"
            className="w-full sm:w-44"
            classNames={{
              values: 'flex-nowrap max-w-xs overflow-hidden',
              itemsWrapper: 'gap-[2px]',
            }}
            label="Модальность"
            data={
              dbModalities.data?.map(am => ({
                value: am.name || '',
                label: am.name || '',
              })) || []
            }
            value={modalities || []}
            onChange={value => {
              value.length ? setModalities(value) : setModalities(undefined);
              setPage(1);
            }}
            limit={50}
            searchable
            clearable
            disableSelectedItemFiltering
          />

          <MultiSelect
            size="xs"
            className="w-full sm:w-44"
            classNames={{
              values: 'flex-nowrap max-w-xs overflow-hidden',
              itemsWrapper: 'gap-[2px]',
            }}
            label="Услуга"
            data={
              dbServices.data?.map(service => ({
                value: service.service_code || '',
                label: service.short_service_name || service.service_name || '',
              })) || []
            }
            value={services || []}
            onChange={value => {
              value.length ? setServices(value) : setServices(undefined);
              setPage(1);
            }}
            limit={50}
            searchable
            clearable
            disableSelectedItemFiltering
          />

          <MultiSelect
            size="xs"
            className="w-full sm:w-44"
            classNames={{
              values: 'flex-nowrap max-w-xs overflow-hidden',
              itemsWrapper: 'gap-[2px]',
            }}
            label="Врач-исполнитель"
            data={
              dbResponsibleUsers.data?.map(ru => ({
                value: ru.id.toString(),
                label: ru.fullname || '',
              })) || []
            }
            value={responsibleUserIds || []}
            onChange={value => {
              value.length ? setResponsibleUserIds(value) : setResponsibleUserIds(undefined);
              setPage(1);
            }}
            limit={50}
            searchable
            clearable
            disableSelectedItemFiltering
          />

          <MultiSelect
            size="xs"
            className="w-full sm:w-44"
            classNames={{
              values: 'flex-nowrap max-w-xs overflow-hidden',
              itemsWrapper: 'gap-[2px]',
            }}
            label="Оплата"
            data={
              dbPaymentTypes.data?.map(pt => ({
                value: pt.id.toString(),
                label: pt.payment_type_rus || '',
              })) || []
            }
            value={paymentTypeIds || []}
            onChange={value => {
              value.length ? setPaymentTypeIds(value) : setPaymentTypeIds(undefined);
              setPage(1);
            }}
            searchable
            clearable
            disableSelectedItemFiltering
          />

          <MultiSelect
            size="xs"
            className="w-full sm:w-44"
            classNames={{
              values: 'flex-nowrap max-w-xs overflow-hidden',
              itemsWrapper: 'gap-[2px]',
            }}
            label="Статус"
            data={Object.keys(dbStatusesMap).map(status => ({
              value: status,
              label: dbStatusesMap[status as keyof typeof dbStatusesMap].label,
            }))}
            value={statuses || []}
            onChange={value => {
              value.length ? setStatuses(value) : setStatuses(undefined);
              setPage(1);
            }}
            searchable
            clearable
            disableSelectedItemFiltering
          />

          <Flex className="flex-wrap items-end gap-2">
            <Flex className="flex-col flex-wrap gap-1">
              <Checkbox
                size="xs"
                label="Некорректный ИИН"
                checked={!!incorrectIIN}
                onChange={e => {
                  setIncorrectIIN(e.currentTarget.checked ? true : undefined);
                  setPage(1);
                }}
              />
              <Checkbox
                size="xs"
                label="Некорректный телефон"
                checked={!!incorrectPhone}
                onChange={e => {
                  setIncorrectPhone(e.currentTarget.checked ? true : undefined);
                  setPage(1);
                }}
              />
            </Flex>

            <Flex className="flex-col gap-1">
              <Checkbox
                size="xs"
                label="Назначены мне"
                checked={!!assignedToMe}
                onChange={e => {
                  setAssignedToMe(e.currentTarget.checked ? true : undefined);
                  setPage(1);
                }}
              />

              <Checkbox
                size="xs"
                label="Военкомат"
                checked={!!hasPatientComments}
                onChange={e => {
                  setHasPatientComments(e.currentTarget.checked ? true : undefined);
                  setPage(1);
                }}
              />
            </Flex>

            <Flex className="flex-col gap-1 self-center">
              <Checkbox
                size="xs"
                label="Ожидают второго чтения"
                checked={!!waitsSecondReader}
                onChange={e => {
                  setWaitsSecondReader(e.currentTarget.checked ? true : undefined);
                  setPage(1);
                }}
              />
            </Flex>
          </Flex>
          <Button
            size="xs"
            onClick={() => {
              setIinOrFio(undefined);
              setDateFrom(undefined);
              setDateTo(undefined);
              setDesc(undefined);
              setModalities(undefined);
              setServices(undefined);
              setResponsibleUserIds(undefined);
              setPaymentTypeIds(undefined);
              setStatuses(undefined);
              setIncorrectIIN(undefined);
              setIncorrectPhone(undefined);
              setAssignedToMe(undefined);
              setHasPatientComments(undefined);
              setWaitsSecondReader(undefined);
              setPage(1);
            }}
            variant="outline"
          >
            Очистить фильтры
          </Button>
        </Flex>

        {studies.isLoading && (
          <Loader
            variant="dots"
            className="mx-auto my-12"
          />
        )}

        {!studies.isLoading && (
          <Table
            className="mt-12"
            highlightOnHover
            withColumnBorders
            withBorder
          >
            <thead>
              <tr>
                <th>ИИН</th>
                <th className="hidden md:table-cell">Фио</th>
                <th>Дата</th>
                <th className="hidden md:table-cell">Описание</th>
                <th className="hidden md:table-cell">Модальность</th>
                <th className="hidden xl:table-cell">Услуга</th>
                <th className="hidden lg:table-cell">Врач-исполнитель</th>
                <th className="hidden lg:table-cell">Оплата</th>
                <th>Статусы</th>
              </tr>
            </thead>
            <tbody>
              {studies.data?.data.map(studyItem => (
                <Study
                  studyItem={studyItem}
                  key={studyItem.id}
                />
              ))}
            </tbody>
          </Table>
        )}

        <Pagination
          className="mt-8 justify-end"
          value={page}
          onChange={setPage}
          total={Math.ceil((studies.data?.count || PER_PAGE) / PER_PAGE)}
        />
      </Box>
    </>
  );
}

function Study({
  studyItem,
}: {
  studyItem: NonNullable<inferRouterOutputs<AppRouter>['study']['getAll']['data'][number]>;
}) {
  const [opened, { open, close }] = useDisclosure(false);
  const modality_names = studyItem.modality_study.map(ms => ms.modalities.name);
  const trpcUtils = trpc.useUtils();

  const study = {
    ...studyItem,
    is_mammo: modality_names.includes('MG'),
    modalities: modality_names.join('\\') || '',
  };

  const studyDate = formatDate(study.date, 'DD.MM.YYYY', ['YYYYMMDD', 'YYYY.MM.DD']);
  // const studyTime = formatDate(study.time, 'HH:mm', ['HHmmss.SSS','HHmmss','HHmm','HH']);

  return (
    <tr
      className="cursor-pointer"
      onClick={() =>
        study.ohif_id || study.is_mammo
          ? window.open(`viewer?StudyInstanceUIDs=${study.ohif_id}`, '_blank')
          : open()
      }
    >
      <td>
        <Box>
          {study.patients?.iin}
          <div onClick={e => e.stopPropagation()}>
            <Modal
              opened={opened}
              onClose={close}
              size={1050}
            >
              <PanelConclusion
                studyId={study.id}
                onSave={() => trpcUtils.study.getAll.invalidate()}
              />
            </Modal>
          </div>
        </Box>
        <p className="block md:hidden">{study.patients?.fullname}</p>
      </td>
      <td className="hidden md:table-cell">{study.patients?.fullname}</td>
      <td>{studyDate}</td>
      <td className="hidden md:table-cell">{study.description}</td>
      <td className="hidden md:table-cell">
        {study.modality_study.map(ms => ms.modality_pacs?.name || ms.modalities.name).join(', ')}
      </td>
      <td className="hidden xl:table-cell">
        <ServicesIcon study={study} />
      </td>
      <td className="hidden lg:table-cell">
        <RewriteResponsibleUserBtn study={study} />
      </td>
      <td className="hidden lg:table-cell">{study.payment_type?.payment_type_rus}</td>
      <td>
        <div
          className="flex flex-wrap gap-1 sm:gap-2"
          onClick={e => e.stopPropagation()}
        >
          <StudyStatusIcon study={study} />
          <SmsStatusIcons study={study} />
          <EditStudyIcon study={study} />
          <AppointmentsIcon study={study} />
          <CloudStatus study={study} />
          {(study.uploaded_to_the_cloud || study.conclusion.length > 0) && (
            <DownloadConclusionIcon study={study} />
          )}
          {(study.status === 'IN_PROGRESS' || study.status === 'CANCELLED') && (
            <CancelStudyIcon study={study} />
          )}
        </div>
      </td>
    </tr>
  );
}
