import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import NewVisitMainData from './components/NewVisitMainData';
import NewVisitAppointments from './components/NewVisitAppointments';
import NewVisitServiceData from './components/NewVisitServiceData';
import NewVisitAnamnesisData from './components/NewVisitAnamnesisData';
import NewVisitPaymentTypeData from './components/NewVisitPaymentTypeData';
import NewVisitModalitySlugsData from './components/NewVisitModalitySlugsData';
import NewVisitDoctorData from './components/NewVisitDoctorData';
import {
  schema,
  serviceModalities,
  serviceDefaultValues,
  NewVisitFormType,
  getBdate,
  hasDisease,
  extractServiceTypeByModalities,
} from './schema';
import useDebounce from '../../hooks/useDebounce';
import NewVisitTreatmentReason from './components/NewVisitTreatmentReason';
import MammoVisitMainData from './components/mammo/MammoVisitMainData';
import MammoVisitAnamnesis from './components/mammo/MammoVisitAnamnesis';
import MammoVisitAssistant from './components/mammo/MammoVisitAssistant';
import MammoVisitVisibleChanges from './components/mammo/MammoVisitVisibleChanges';
import MammoVisitFirstReader from './components/mammo/MammoVisitFirstReader';
import MammoVisitSecondReader from './components/mammo/MammoVisitSecondReader';
import { checkIinBinSum } from '../../utils/checkIinBinSum';
import { Skeleton, Button } from '@mantine/core';
import { modals } from '@mantine/modals';
import dayjs from 'dayjs';
import { Studies } from '../../app/src/types/StudyTypes';
import { useTranslation } from 'react-i18next';
import { trpc } from '../../app/trpc';
import { notifications } from '@mantine/notifications';

interface Props {
  study?: Studies;
  showPartial?: 'mammo' | 'services';
  onClose?: () => void;
}

export default function NewVisitModal({ study, showPartial, onClose }: Props) {
  const trpcUtils = trpc.useUtils();
  const user = trpc.general.getUser.useQuery();
  const mammoQ = study?.mammo_questionnaire[0];
  const { t } = useTranslation('Custom');

  const useFormProps = useForm<NewVisitFormType>({
    resolver: yupResolver(schema),
    defaultValues: {
      serviceModality: study
        ? extractServiceTypeByModalities(study.modality_study.map(s => s.modalities.name!))
        : undefined,
      document_type: study?.patients?.document_type || 'IIN',
      iin: study?.patients?.iin || '',
      fullname: '',
      bdate: undefined,
      phone: '',
      email: '',

      payment_type_slug: '',
      insurance_organizations_id: study?.insurance_organizations_id?.toString() || '',
      services: [serviceDefaultValues],

      treatment_reason_code: '',
      complaints: '',
      medical_history: '',

      has_postponed_operations: '',
      has_metalforeign_bodies: '',
      has_electronic_devices: '',

      postponed_operations: '',
      metalforeign_bodies: '',
      electronic_devices: '',

      first_reader_responsible_user_id: study?.first_reader_responsible_user_id?.toString() || '',
      responsible_user_id: study?.responsible_user_id?.toString() || '',
      physician_user_id: study?.physician_user_id?.toString() || '',

      // mammo *******
      visit_type: mammoQ?.visit_type || undefined,
      is_first_mammo:
        mammoQ?.is_first_mammo === true ? 'yes' : mammoQ?.is_first_mammo === false ? 'no' : '',
      is_first_screening:
        mammoQ?.is_first_screening === true
          ? 'yes'
          : mammoQ?.is_first_screening === false
          ? 'no'
          : '',
      has_other_mg_studies:
        mammoQ?.has_other_mg_studies === true
          ? 'yes'
          : mammoQ?.has_other_mg_studies === false
          ? 'no'
          : '',
      has_prev_mg_studies:
        mammoQ?.has_prev_mg_studies === true
          ? 'yes'
          : mammoQ?.has_prev_mg_studies === false
          ? 'no'
          : '',
      has_menopause:
        mammoQ?.has_menopause === true ? 'yes' : mammoQ?.has_menopause === false ? 'no' : '',
      heredity: mammoQ?.heredity === true ? 'yes' : mammoQ?.heredity === false ? 'no' : '',
      hormone_replacement_therapy:
        mammoQ?.hormone_replacement_therapy === true
          ? 'yes'
          : mammoQ?.hormone_replacement_therapy === false
          ? 'no'
          : '',
      has_complaints:
        mammoQ?.has_complaints === true ? 'yes' : mammoQ?.has_complaints === false ? 'no' : '',

      first_screening_data: mammoQ?.first_screening_data || '',
      other_mg_studies_data: mammoQ?.other_mg_studies_data || '',
      menopause_age: mammoQ?.menopause_age || '',
      breast_cancer: mammoQ?.breast_cancer || '',
      breast_cancer_other: mammoQ?.breast_cancer_other || '',
      complaints_data: mammoQ?.complaints_data || '',
      mammo_total_number: mammoQ?.mammo_total_number?.toString() || '',

      assistant_note: mammoQ?.assistant_note || '',
      assistant_date: new Date(mammoQ?.assistant_date || study?.created_at || Date.now()),
      closed_on_damumed: mammoQ?.closed_on_damumed || false,

      cyst_aspiration: {
        right: mammoQ?.cyst_aspiration?.right
          ? dayjs(mammoQ?.cyst_aspiration?.right).toDate()
          : undefined,
        left: mammoQ?.cyst_aspiration?.left
          ? dayjs(mammoQ?.cyst_aspiration?.left).toDate()
          : undefined,
      },
      sector_resection: {
        right: mammoQ?.sector_resection?.right
          ? dayjs(mammoQ?.sector_resection?.right).toDate()
          : undefined,
        left: mammoQ?.sector_resection?.left
          ? dayjs(mammoQ?.sector_resection?.left).toDate()
          : undefined,
      },
      mastectomy: {
        right: mammoQ?.mastectomy?.right ? dayjs(mammoQ?.mastectomy?.right).toDate() : undefined,
        left: mammoQ?.mastectomy?.left ? dayjs(mammoQ?.mastectomy?.left).toDate() : undefined,
      },
      organ_reservation_operation: {
        right: mammoQ?.organ_reservation_operation?.right
          ? dayjs(mammoQ?.organ_reservation_operation?.right).toDate()
          : undefined,
        left: mammoQ?.organ_reservation_operation?.left
          ? dayjs(mammoQ?.organ_reservation_operation?.left).toDate()
          : undefined,
      },
      radiation_therapy: {
        right: mammoQ?.radiation_therapy?.right
          ? dayjs(mammoQ?.radiation_therapy?.right).toDate()
          : undefined,
        left: mammoQ?.radiation_therapy?.left
          ? dayjs(mammoQ?.radiation_therapy?.left).toDate()
          : undefined,
      },
      other_operations: {
        right: mammoQ?.other_operations?.right
          ? dayjs(mammoQ?.other_operations?.right).toDate()
          : undefined,
        left: mammoQ?.other_operations?.left
          ? dayjs(mammoQ?.other_operations?.left).toDate()
          : undefined,
      },

      scar_mole_papilloma: {
        right: mammoQ?.scar_mole_papilloma?.right || false,
        left: mammoQ?.scar_mole_papilloma?.left || false,
      },
      nipple_changes: {
        right: mammoQ?.nipple_changes?.right || false,
        left: mammoQ?.nipple_changes?.left || false,
      },
      nipple_discharge: {
        right: mammoQ?.nipple_discharge?.right || false,
        left: mammoQ?.nipple_discharge?.left || false,
      },
      deformation_compaction: {
        right: mammoQ?.deformation_compaction?.right || false,
        left: mammoQ?.deformation_compaction?.left || false,
      },

      direct_projection: {
        right: mammoQ?.direct_projection?.right?.toString() || '',
        left: mammoQ?.direct_projection?.left?.toString() || '',
      },
      oblique_projection: {
        right: mammoQ?.oblique_projection?.right?.toString() || '',
        left: mammoQ?.oblique_projection?.left?.toString() || '',
      },
    },
    mode: 'onChange',
  });

  const currentOrgId = study?.organization_id || user.data!.organization_user[0]!.organization_id!;

  const document_type = useFormProps.watch('document_type');
  const iin = useFormProps.watch('iin');
  const debouncedIin = useDebounce<string>(iin || '', 1000);
  const payment_type_slug = useFormProps.watch('payment_type_slug');
  const serviceModality = useFormProps.watch('serviceModality');

  const { data: patientData, isInitialLoading: appointmentsIsLoading } =
    trpc.visit.getVisitData.useQuery(
      {
        documentType: document_type,
        iin: document_type === 'IIN' ? iin : debouncedIin,
        modalities: serviceModalities[serviceModality]?.modality_slugs as any,
        studyId: study?.id,
      },
      {
        enabled:
          study?.patients?.iin === iin ||
          (document_type === 'IIN' ? iin?.length === 12 && checkIinBinSum(iin) : !!iin),
        cacheTime: 0,
      }
    );

  useEffect(() => {
    if (patientData) {
      const anamnesis = study?.anamesis[0] || patientData?.patient?.anamesis[0];
      const treatmentReasonCode = anamnesis?.treatment_reason_code?.toString();

      const currentVals = useFormProps.getValues();

      useFormProps.reset({
        ...useFormProps.getValues(),
        fullname: patientData.patient?.fullname || currentVals.fullname,
        bdate:
          getBdate(patientData.patient?.bdate || '', patientData.patient?.iin || '') ||
          currentVals.bdate,

        phone: patientData.patient?.phone || currentVals.phone,
        email: patientData.patient?.email || currentVals.email,

        payment_type_slug: study
          ? study.payment_type?.payment_type
          : patientData.appointments.length > 0
          ? 'OSMS'
          : 'PRIVATELY',

        treatment_reason_code: treatmentReasonCode
          ? treatmentReasonCode
          : patientData.appointments.find(a => a.service_code === 'C03.082.005')
          ? '17'
          : '',

        complaints: anamnesis?.complaints || currentVals.complaints,
        medical_history: anamnesis?.medical_history || currentVals.medical_history,

        has_postponed_operations: hasDisease('postponed_operations', anamnesis),
        has_metalforeign_bodies: hasDisease('metalforeign_bodies', anamnesis),
        has_electronic_devices: hasDisease('electronic_devices', anamnesis),

        postponed_operations: anamnesis?.postponed_operations || currentVals.postponed_operations,
        metalforeign_bodies: anamnesis?.metalforeign_bodies || currentVals.metalforeign_bodies,
        electronic_devices: anamnesis?.electronic_devices || currentVals.electronic_devices,
      });

      study && showPartial !== 'mammo' && useFormProps.trigger();
    }
  }, [patientData, showPartial, study, useFormProps]);

  const upsertVisit = trpc.visit.upsertVisit.useMutation({
    onSettled: (_, error) => {
      notifications.show({
        message: error ? 'Что-то пошло не так' : 'Данные сохранены',
        color: error ? 'red' : 'green',
      });

      if (onClose) {
        onClose();
      } else {
        modals.closeAll();
        trpcUtils.study.getAll.invalidate();
        trpcUtils.study.get.invalidate();
      }
    },
  });

  async function onSubmit() {
    if (!(study ? true : await useFormProps.trigger())) {
      return;
    }

    const data = useFormProps.getValues();

    upsertVisit.mutate({
      studyId: study?.id || null,
      modality_slugs: (!study ? serviceModalities[serviceModality]?.modality_slugs : []) as any,
      document_type: data.document_type,
      iin: data.iin,
      fullname: data.fullname || null,
      bdate: data.bdate ? dayjs(data.bdate).format('YYYY-MM-DD') : null,
      phone: data.phone || null,
      email: data.email || null,
      payment_type_slug: data.payment_type_slug || null,
      insurance_organizations_id: data.insurance_organizations_id
        ? +data.insurance_organizations_id
        : null,
      services: data.services
        .filter(ss => ss.service_code)
        .map(s => {
          return {
            service_code: s.service_code!,
            service_count: s.service_count || 1,
            comment: s.comment || null,
            appointment_id: s.appointment_id || null,
          };
        }),
      first_reader_responsible_user_id: data.first_reader_responsible_user_id
        ? +data.first_reader_responsible_user_id
        : null,
      responsible_user_id: data.responsible_user_id ? +data.responsible_user_id : null,

      physician_user_id:
        !study && user.data?.roleHelper.isAssistant
          ? user.data!.id
          : data.physician_user_id
          ? +data.physician_user_id
          : null,
      anamesis: {
        treatment_reason_code: data.treatment_reason_code ? +data.treatment_reason_code : null,
        complaints: data.complaints || null,
        medical_history: data.medical_history || null,
        postponed_operations: data.postponed_operations || null,
        metalforeign_bodies: data.metalforeign_bodies || null,
        electronic_devices: data.electronic_devices || null,
      },
      mammo_questionnaire:
        data.serviceModality === 'MG'
          ? {
              is_first_mammo: data.is_first_mammo === 'yes',
              is_first_screening: data.is_first_screening === 'yes',
              first_screening_data: data.first_screening_data || null,
              has_other_mg_studies: data.has_other_mg_studies === 'yes',
              other_mg_studies_data: data.other_mg_studies_data || null,
              has_prev_mg_studies: data.has_prev_mg_studies === 'yes',
              has_menopause: data.has_menopause === 'yes',
              menopause_age: data.menopause_age || null,
              heredity: data.heredity === 'yes',
              breast_cancer: data.breast_cancer || null,
              breast_cancer_other: data.breast_cancer_other || null,
              hormone_replacement_therapy: data.hormone_replacement_therapy === 'yes',
              has_complaints: data.has_complaints === 'yes',
              complaints_data: data.complaints_data || null,

              cyst_aspiration: {
                right: data.cyst_aspiration?.right
                  ? dayjs(data.cyst_aspiration?.right).format('YYYY-MM-DD')
                  : null,
                left: data.cyst_aspiration?.left
                  ? dayjs(data.cyst_aspiration?.left).format('YYYY-MM-DD')
                  : null,
              },
              sector_resection: {
                right: data.sector_resection?.right
                  ? dayjs(data.sector_resection?.right).format('YYYY-MM-DD')
                  : null,
                left: data.sector_resection?.left
                  ? dayjs(data.sector_resection?.left).format('YYYY-MM-DD')
                  : null,
              },
              mastectomy: {
                right: data.mastectomy?.right
                  ? dayjs(data.mastectomy?.right).format('YYYY-MM-DD')
                  : null,
                left: data.mastectomy?.left
                  ? dayjs(data.mastectomy?.left).format('YYYY-MM-DD')
                  : null,
              },
              organ_reservation_operation: {
                right: data.organ_reservation_operation?.right
                  ? dayjs(data.organ_reservation_operation?.right).format('YYYY-MM-DD')
                  : null,
                left: data.organ_reservation_operation?.left
                  ? dayjs(data.organ_reservation_operation?.left).format('YYYY-MM-DD')
                  : null,
              },
              radiation_therapy: {
                right: data.radiation_therapy?.right
                  ? dayjs(data.radiation_therapy?.right).format('YYYY-MM-DD')
                  : null,
                left: data.radiation_therapy?.left
                  ? dayjs(data.radiation_therapy?.left).format('YYYY-MM-DD')
                  : null,
              },
              other_operations: {
                right: data.other_operations?.right
                  ? dayjs(data.other_operations?.right).format('YYYY-MM-DD')
                  : null,
                left: data.other_operations?.left
                  ? dayjs(data.other_operations?.left).format('YYYY-MM-DD')
                  : null,
              },

              scar_mole_papilloma: {
                right: data.scar_mole_papilloma?.right || false,
                left: data.scar_mole_papilloma?.left || false,
              },
              nipple_changes: {
                right: data.nipple_changes?.right || false,
                left: data.nipple_changes?.left || false,
              },
              nipple_discharge: {
                right: data.nipple_discharge?.right || false,
                left: data.nipple_discharge?.left || false,
              },
              deformation_compaction: {
                right: data.deformation_compaction?.right || false,
                left: data.deformation_compaction?.left || false,
              },

              assistant_pic: data.assistant_pic
                ? data.assistant_pic.substring(data.assistant_pic.indexOf(',') + 1)
                : null,
              direct_projection: {
                right: data.direct_projection?.right ? +data.direct_projection.right : null,
                left: data.direct_projection?.left ? +data.direct_projection.left : null,
              },
              oblique_projection: {
                right: data.oblique_projection?.right ? +data.oblique_projection.right : null,
                left: data.oblique_projection?.left ? +data.oblique_projection.left : null,
              },
              mammo_total_number: data.mammo_total_number ? +data.mammo_total_number : null,
              assistant_note: data.assistant_note || null,
              assistant_date: data.assistant_date
                ? dayjs(data.assistant_date).format('YYYY-MM-DD')
                : null,
              closed_on_damumed: data.closed_on_damumed || false,
              visit_type: data.visit_type || null,
            }
          : null,
    });
  }

  const downloadPdf = trpc.mammo.generatePdf.useMutation({
    onSettled: (pdf, error) => {
      if (error) {
        notifications.show({
          message: 'Что-то пошло не так',
          color: 'red',
        });
      }

      if (pdf) {
        const downloadLink = document.createElement('a');
        downloadLink.href = `data:application/pdf;base64,${pdf}`;
        downloadLink.download = `${study?.patients?.iin || ''} Направление на вторую читку.pdf`;
        downloadLink.click();
      }
    },
  });

  return (
    <div>
      <form>
        <FormProvider {...useFormProps}>
          {!showPartial && <NewVisitModalitySlugsData disabled={!!study} />}

          <div className={`${!serviceModality ? 'pointer-events-none opacity-20' : ''}`}>
            {!showPartial && <NewVisitMainData />}
            {showPartial !== 'mammo' && (
              <>
                <NewVisitPaymentTypeData />
                <Skeleton
                  visible={appointmentsIsLoading}
                  className="mt-4"
                >
                  <NewVisitAppointments
                    appointments={patientData?.appointments || []}
                    disabled={study?.status === 'FINISHED'}
                    study_id={study?.id}
                  />

                  {patientData?.appointments.length === 0 && payment_type_slug === 'OSMS' && (
                    <p className="ml-6 mt-2 text-red-500">
                      По данному пациенту нет направлений в базе. Данное исследование нужно будет
                      вручную закрывать в Дамумед
                    </p>
                  )}

                  {patientData && (
                    <NewVisitServiceData
                      appointments={patientData?.appointments || []}
                      currentOrgId={currentOrgId}
                      study={study}
                    />
                  )}
                </Skeleton>
              </>
            )}

            <div className="mt-8">
              {showPartial !== 'mammo' && <NewVisitTreatmentReason />}
              {!showPartial && <NewVisitAnamnesisData />}
            </div>

            {serviceModality === 'MG' && showPartial !== 'services' && (
              <fieldset className="mt-8 border-t">
                <legend className="mx-auto px-4 text-xl font-bold">
                  {t('Second reading appointment')}
                </legend>

                <MammoVisitMainData />
                <MammoVisitAnamnesis />
                <MammoVisitVisibleChanges />
                <MammoVisitAssistant study={study} />
                <MammoVisitFirstReader
                  study={study}
                  showPartial={showPartial}
                />
                <MammoVisitSecondReader
                  study={study}
                  showPartial={showPartial}
                />
              </fieldset>
            )}

            {showPartial !== 'services' && (
              <NewVisitDoctorData
                currentOrgId={currentOrgId}
                study={study}
              />
            )}
          </div>
        </FormProvider>

        <div className="mt-4 flex flex-col items-center sm:flex-row">
          <div className="text-right sm:w-1/3"></div>
          <div className="flex-1 sm:ml-3">
            {(showPartial !== 'mammo' || user.data?.roleHelper.isAssistant) && (
              <Button
                className="mr-3"
                loading={upsertVisit.isLoading}
                onClick={onSubmit}
              >
                {t('Save')}
              </Button>
            )}

            {study?.is_mammo && (
              <Button
                variant="outline"
                className="mr-3"
                loading={downloadPdf.isLoading}
                onClick={() => downloadPdf.mutate({ studyId: study?.id })}
              >
                {t('Download PDF')}
              </Button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
